import axios from 'axios'

export class Turma {
  static async obtemTodos (ano,n,escola,segmento) {
    return axios.get('/'+ano+'/turmas?page='+n+'&escola='+escola+'&segmento='+segmento)
  }
  static async obtemTodosCoordenacao (servidor_id,tipo,ano,n) {
    return axios.get('/turmas/'+servidor_id+'/tipo/'+tipo+'/'+ano+'?page='+n)
  }
  static async obtemUm (id) {
    return axios.get('/turmas/'+id,{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }
  static async obtemPorEscola (id) {
    return axios.get('/turmas/'+id+'/escola')
  }
  static async obtemPorEscolaAno (id,ano) {
    return axios.get('/'+ano+'/turmas/'+id+'/escola')
  }

  static async disciplinaNaTurma (turma_id) {
    return axios.get('turma/disciplina',  {params: { turma_id }});
  }
  static async cadastrar (payload) {
    return axios.post('/turmas', payload)
  }
  static async editar (id,payload) {
    return axios.put('/turmas/'+id, payload)
  }
  static async addDisciplina (payload) {
    return axios.post('/turma/disciplina', payload)
  }

  static async turmasDisciplina (payload) {
    return axios.get('/turma/disciplina?turma_id='+payload)
  }
  static async turmasAvancada (payload) {
    return axios.post('/turmas/avanc?ano='+payload.ano+'&escola_id='+payload.escola_id+'&segmento_id='+payload.segmento_id+'&serie_id='+payload.serie_id);
  }

  static async visualizarDisciplinas(turma_id) {
    return axios.get('/disciplina?turma_id='+turma_id);
  }
  static async professorDaTurma (turma_id) {
    return axios.get('turma/professor?turma_id='+turma_id);
  }

  static async addProfessorTurma (payload) {
    return axios.post('turma/professor', payload);
  }

  static async EditarProfessorTurma (payload) {
    return axios.put('turma/professor',payload);
  }

  static async obtemNotasAlunos (turma_id) {
    return axios.get('/nota/alunos/turma/'+turma_id);
  }

  static async obtemTurmaDisciplina (id) {
    return axios.get('/turma/disciplina/'+id);
  }

  static async obtemTurmaProfessor (id) {
    return axios.get('/turma/professor/'+id);
  }

  static async obtemTurmaProfessorV2 (payload) {
    return axios.post('turma/professor/v2',payload);
  }

  static async obtemTurmaCoordenadorV1 (payload) {
    return axios.post('turma/coordenador/v1',payload);
  }
  static async buscarProfessoresDaTurma (turma_id) {
    return axios.get('buscarprofessor/turma/'+turma_id,{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }
  static async obtemPresencaTudo (turma_id) {
    return axios.get('presenca/turma/'+turma_id+'/tudo',{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }

  static async ativacaoDaTurma (payload) {
    return axios.post('turma/ativar', payload);
  }

  static async obtemAlunosTurma (turma_id) {
    return axios.get('/turma/alunos?turma_id='+turma_id,{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }
  static async verificarProfessoresDisciplina (turma_id) {
    return axios.post('turma/disciplinas/disciplinacomprofessor?turma_id='+turma_id)
  }
  static async obterProfessorTurmasAno (payload) {
    return axios.get('/turmas/professor/'+payload.professor+'/'+payload.ano);
  }

  static async obtemTodasAsDisciplinasTurma (turma_id) {
    return axios.get('turma/visualizar/disciplinas/'+turma_id);
  }

  static async obtemProfessorDisciplinasEspecialista (seg,disc,escola_id) {
    return axios.get('turma/professor/ministra/disciplina/'+seg+'/'+disc+'/'+escola_id);
  }

  static async verificaNaDisciplinaProfessor (disc,turma) {
    return axios.get('turma/visu/disciplina/'+disc+'/'+turma,{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }
  static async deletarProfessorDaDisciplina (payload) {
    return axios.delete('turma/professor?turma_id='+payload.turma_id+'&turma_disciplina_id='+payload.turma_disciplina_id +'&professor_id='+payload.professor_id);
  }
  static async deletarDisciplinaDaTurma(payload) {
    return axios.delete('turma/disciplina?turma_id='+payload.turma_id+'&disciplina_id='+payload.disciplina_id);
  }

  static async aturma (payload) {
    return axios.post('turma/resp',payload);
  }

  static async aturmaConfi (payload) {
    return axios.post('turma/configuracao',payload);
  }

  static async ativacãoTotalTurma (payload) {
    return axios.post('turma/total/ativar',payload);
  }

  static async turmaVerTudo (id) {
    return axios.get('turma/visualizar/turma/'+id);
  }
  static async showEscolaSegmentoTurma (payload) {
    return axios.get('turmas/ano/'+payload.ano+'/escola/'+payload.escola_id+'/segmento/'+payload.segmento_id);
  }

  static async deletarTurmaAmbienteCoordenacao(id) {
    return axios.delete('turmas/ambientecoordenador/'+id);
  }
  static async deletarTurmaAmbienteGestao(id) {
    return axios.delete('turmas/ambientegestor/'+id);
  }

  static async pesqTurmaCompleto (payload) {
    return axios.get('/turmas/pesquisa/'+payload+'/completa')
  }

  static async pesqTurmaPorSerieSegmento (payload) {
    return axios.get('turmas/escola/'+payload.escola+'/serie/'+payload.serie+'/segmento/'+payload.segmento)
  }

  static async alunoEmUmaTurma (turma) {
    return axios.get('alunosdaTurma/'+turma,{
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }
  static async obterTodasTurmasDoProfessor (turma) {
    return axios.get('turmas/professor/todas/'+turma.professor+'/'+turma.ano);
  }
  static async obterTodasDisciplinaDeUmProfessorEmUmaTurma (payload) {
    return axios.get('buscarprofessor/turma/disciplinas/'+payload.turma_id+'/'+payload.professor_id);
  }
}
